import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import MainLayout from '../components/main-layout'
import '../styles/main.scss'
import ChevronLeft from '../images/chevron-left.inline.svg'
import ChevronRight from '../images/chevron-right.inline.svg'
import Footer from '../components/footer'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import PageWrapper from '../components/page/page-wrapper'
import SearchProvider, { SearchContext } from '../contexts/searchContext'
import { RoomService, RoomType } from '../../../../shared/types/cms'
import DatePicker from '../components/datepicker'
import ImageGallery from 'react-image-gallery'
import { AuthContext, AuthStatus } from '../contexts/authContext'
import { Helmet } from 'react-helmet'
import { CmsRoomServices, CmsRoomTypes } from '../services/cms'
import PageContextProvider from '../contexts/pageContext'
import Markdown from '../components/markdown'
import { RoomServiceType } from '../../../../shared/types/RoomServiceType'
import defaultServiceIcon from '../images/icon-circle-check-outline.svg'
import VirtualTourIcon from '../images/icon-3d-tour.inline.svg'
import Header from '../components/header/header'
import roomImagePlaceholder from '../images/room-placeholder.jpg'
import IconCross from '../images/icon-cross.inline.svg'
import { getRoomUrl } from '../../../../shared/config/room'
import VirtualTourBlockComponent from '../components/page/virtual-tour-block-component'

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
        defaultLanguage
        siteUrl
      }
    }
  }
`

interface RoomPageProps extends PageProps {
  pageContext: {
    roomType: RoomType
    language: string
    localizedPaths: Record<string, string>
  }
  data: {
    site: {
      siteMetadata: {
        titleTemplate: string
        defaultLanguage: string
        siteUrl: string
      }
    }
  }
}

export const RoomPage: React.FC<RoomPageProps> = (props) => {
  const { language, roomType } = props.pageContext
  const { t } = useTranslation()
  const localRoomName = CmsRoomTypes.getLocalName(roomType, language)

  return (
    <>
      <Helmet
        title={t(`Room type`) + ` ` + localRoomName}
        titleTemplate={props.data.site.siteMetadata.titleTemplate}
        defer={false}
        meta={[
          {
            property: `og:url`,
            content:
              props.data.site.siteMetadata.siteUrl +
              (language === props.data.site.siteMetadata.defaultLanguage ? `` : `/${language}`) +
              getRoomUrl(roomType.id, CmsRoomTypes.getLocalName(roomType, language)),
          },
          { property: `og:type`, content: `website` },
          {
            property: `og:title`,
            content: props.data.site.siteMetadata.titleTemplate.replace(`%s`, t(`Room type`) + ` ` + localRoomName),
          },
          { property: `og:description`, content: CmsRoomTypes.getLocalDescription(roomType, language) },
          {
            property: `og:image`,
            content: roomType.images[0]?.url ?? props.data.site.siteMetadata.siteUrl + roomImagePlaceholder,
          },
          { name: `twitter:card`, content: `summary_large_image` },
        ]}
      />
      <PageContextProvider pageContext={props.pageContext}>
        <PageWrapper>
          <SearchProvider>
            <RoomPageInner {...props} />
          </SearchProvider>
        </PageWrapper>
      </PageContextProvider>
    </>
  )
}

export const RoomPageInner: React.FC<RoomPageProps> = ({ pageContext, data }) => {
  const { t } = useTranslation()
  const language = pageContext.language
  const { getUrlSearchParams, startDate, setStartDate } = React.useContext(SearchContext)
  const { authStatus, setAuthFlowVisible } = React.useContext(AuthContext)
  const MobileGallery = React.useRef<ImageGallery>(null)
  const [fullGalleryVisible, setFullGalleryVisible] = React.useState(false)
  const roomType = pageContext.roomType

  const includedServices = React.useMemo<RoomService[]>(() => {
    return roomType?.services?.filter((service) => service.type === RoomServiceType.Standard) || []
  }, [roomType?.services])
  /*const ondemandServices = React.useMemo<RoomService[]>(() => {
    return roomType.services.filter((service) => service.type === RoomServiceType.OnDemand)
  }, [roomType.services])*/

  const totalString = (((roomType?.downpaymentData?.value ?? 0) + (roomType?.priceData?.value ?? 0)) / 100).toFixed(2)

  const roomTypeImages = roomType.images.map((image) => ({
    original: image.url,
    small: image.formats.small.url,
    thumbnail: image.formats.thumbnail.url,
  }))
  const localRoomName = CmsRoomTypes.getLocalName(roomType, language)

  const BookingSummary: React.FC = () => (
    <div className="booking-summary">
      <div className={`booking-summary__room d-block d-lg-none`}>
        <div
          className="booking-summary__room-image cursor-pointer"
          onClick={() => {
            MobileGallery.current?.fullScreen()
          }}
        >
          <img src={roomTypeImages[0]?.small ?? roomImagePlaceholder} />
          <button
            type="button"
            className="booking-summary__room-image-expand image-gallery-icon image-gallery-fullscreen-button"
            onClick={() => {
              MobileGallery.current?.fullScreen()
            }}
            aria-label="Open Fullscreen"
          >
            <svg strokeWidth={2} width="26" height="26" viewBox="0 0 26 26">
              <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
            </svg>
          </button>
        </div>
      </div>
      <div className="booking-summary__dates-control d-flex">
        <div className="booking-summary__start-date col-12">
          <label>{t(`Start of rental`)}</label>
          <div>
            <DatePicker value={startDate} onChange={setStartDate} placeholderText="" />
          </div>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="booking-summary__label col-6">{t(`Start of contract`)}</div>
          <div className="booking-summary__value col-6">{startDate}</div>
          <div className="booking-summary__label col-6">{t(`Monthly rent`)}</div>
          <div className="booking-summary__value col-6">{roomType.priceData.text} €</div>
          <div className="booking-summary__label col-6">{t(`Downpayment`)}</div>
          <div className="booking-summary__value col-6">{roomType?.downpaymentData?.text} €</div>
        </div>
      </div>
      <div className="horizontal-separator" />
      <div className="booking-summary__total">
        <div className="row">
          <div className="booking-summary__label col-6">{t(`Summary`)}</div>
          <div className="booking-summary__value col-6">{totalString} €</div>
        </div>
      </div>
      <div className="horizontal-separator" />
      <div>
        {authStatus === AuthStatus.SignedIn ? (
          <Link
            to={`/application` + getUrlSearchParams({ room: roomType.id.toString() })}
            state={{ roomTypeId: roomType.id, startDate: startDate }}
          >
            <button className="booking-summary__button">
              {t(`Rent room`)}
              <ChevronRight />
            </button>
          </Link>
        ) : (
          <button className="booking-summary__button" onClick={() => setAuthFlowVisible(true)}>
            {t(`Rent room`)}
            <ChevronRight />
          </button>
        )}
      </div>
    </div>
  )

  return (
    <MainLayout className="room-details-page">
      <Header />

      <img className="room-details__background-image" src={roomTypeImages[0]?.original ?? roomImagePlaceholder} />
      <div className={`room-details ${fullGalleryVisible ? `room-details--gallery-fullscreen` : ``}`}>
        <div className={`container-xl`}>
          <div className="row">
            <div className="d-block d-lg-none col-12 p-0">
              <BookingSummary />
            </div>
            <div className="room-details__wrapper col-lg-12 col-xl-8 order-lg-2 order-xl-1 position-relative">
              <div className="room-details__return">
                <Link to={`/listings/` + getUrlSearchParams()}>
                  <ChevronLeft className="chevron chevron--left" />
                  {t(`Back`)}
                </Link>
              </div>

              <div className="room-details__section">
                <h4 className="collapsible-section__label">{t(`Room type`) + ` ` + localRoomName}</h4>
                <div className="collapsible-section__content">
                  <Markdown>{CmsRoomTypes.getLocalDescription(roomType, language)}</Markdown>
                </div>
              </div>

              <div className={`room-details__section room-details__gallery`}>
                <ImageGallery
                  ref={MobileGallery}
                  additionalClass={`room-gallery ${fullGalleryVisible ? `room-gallery--mobile` : ``}`}
                  items={roomTypeImages}
                  showPlayButton={false}
                  showBullets
                  showThumbnails={false}
                  useBrowserFullscreen={false}
                  onScreenChange={(state) => {
                    setFullGalleryVisible(state)
                  }}
                  renderCustomControls={() => {
                    return (
                      <button
                        className="room-gallery__close d-lg-none image-gallery-icon"
                        onClick={() => MobileGallery.current?.exitFullScreen()}
                      >
                        <IconCross className="image-gallery-svg" />
                      </button>
                    )
                  }}
                />
              </div>

              {!!roomType.location.virtualTour && (
                <div className="room-details__section">
                  <h4 className="collapsible-section__label justify-content-start">
                    {t(`3D virtual Tour`)}
                    <VirtualTourIcon className="flex-shrink-0 ms-4 ms-lg-5" height="1em" width="auto" />
                  </h4>
                  <div className="collapsible-section__content">
                    {/* TODO: Add icon and text (to be provided) 
                    <p>{t(`room_coliving_description`)}</p>
                    */}
                    <VirtualTourBlockComponent data={roomType.location.virtualTour} />
                  </div>
                </div>
              )}

              <div className="room-details__section">
                <h4 className="collapsible-section__label">{t(`Co-living life`)}</h4>
                <div className="collapsible-section__content">
                  <p>{t(`room_coliving_description`)}</p>
                </div>
              </div>

              <div className="room-details__section">
                <h4 className="collapsible-section__label">{t(`Services included`)}</h4>
                <div className="collapsible-section__content">
                  <div className="row">
                    {includedServices.map((service, index) => (
                      <div key={`included_service_` + index} className="included-service col-12 col-md-6 d-flex">
                        <div>
                          <h4>
                            <img className="included-service__icon" src={service.icon?.url ?? defaultServiceIcon} />
                            {CmsRoomServices.getLocalName(service, language)}
                          </h4>
                          <Markdown>{CmsRoomServices.getLocalDescription(service, language)}</Markdown>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="room-details__summary col-lg-12 col-xl-4 order-lg-1 order-xl-2 d-none d-lg-block">
              <BookingSummary />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </MainLayout>
  )
}
export default RoomPage
